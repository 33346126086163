import React, { useState } from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import axios from 'axios';

export default function WindowsStyles() {
    const [images, setImages] = useState({});

    React.useEffect(() => {
        axios.get('https://api-dot-wrightglazing.nw.r.appspot.com/api/get_windows_images', {responseType: 'json'}).then((response) => {
            setImages(response.data)
        })
        
    }, []);

    const imgStyle = {
        maxWidth: '100%',
        height: '100%',
        objectFit: 'cover', // Ensure the image covers the entire container
        borderRadius: 5,
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 2,
        marginRight: 2
    };

    return (
        <Grid container sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 3 }}>
            <Grid item xs={12} sx={{ backgroundColor: '#16a1d8', textAlign: 'center' }} p={3}>
                <Typography variant="h3" component="div" sx={{ color: '#FFF', fontStyle: 'italic' }}>
                    Start exploring
                </Typography>
                <Typography variant="h5" component="div" sx={{ textAlign: 'center', fontStyle: 'italic', color: '#FFF' }}>
                    Dive into our diverse range of styles
                </Typography>
            </Grid>
            <Grid container xs={11} p={{md: 3, xs: 1}}>
                <Grid item md={4} xs={12}>
                    <Button component="a" href={'/upvc-windows'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} p={1}>
                            <img src={images.casement} alt="Logo" style={imgStyle} />
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ textAlign: 'left' }} p={2}>
                            UPVC Windows
                        </Typography>
                        <Divider variant="middle" />
                        <Typography variant="p" component="div" sx={{ textAlign: 'left' }} p={2}>
                            Effortless elegance; swings open for unmatched airflow.
                        </Typography>

                    </Button>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Button component="a" href={'/flush'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} p={1}>
                            <img src={images.flush} alt="Logo" style={imgStyle} />
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ textAlign: 'left' }} p={2}>
                            Flush
                        </Typography>
                        <Divider variant="middle" />
                        <Typography variant="p" component="div" sx={{ textAlign: 'left' }} p={2}>
                            Sleek, seamless design for a modern, minimalist appeal.
                        </Typography>
                    </Button>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Button component="a" href={'/sash'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} p={1}>
                            <img src={images.sash} alt="Logo" style={imgStyle} />
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ textAlign: 'left' }} p={2}>
                            Sash
                        </Typography>
                        <Divider variant="middle" />
                        <Typography variant="p" component="div" sx={{ textAlign: 'left' }} p={2}>
                            Timeless beauty meets contemporary efficiency in vertical elegance.
                        </Typography>
                    </Button>
                </Grid>


                {/* <Grid item md={4} xs={12}>
                    <Button component="a" href={'/bay'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} p={1}>
                            <img src={images.bay} alt="Logo" style={imgStyle} />
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ textAlign: 'left' }} p={2}>
                            Bay
                        </Typography>
                        <Divider variant="middle" />
                        <Typography variant="p" component="div" sx={{ textAlign: 'left' }} p={2}>
                            Expansive views, inviting spaces; a luxurious touch.
                        </Typography>
                    </Button>
                </Grid> */}

                
                <Grid item md={6} xs={12}>
                    <Button component="a" href={'/aluminium'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} p={1}>
                            <img src={images.aluminium} alt="Logo" style={imgStyle} />
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ textAlign: 'left' }} p={2}>
                            Aluminum
                        </Typography>
                        <Divider variant="middle" />
                        <Typography variant="p" component="div" sx={{ textAlign: 'left' }} p={2}>
                            Sleek durability, for stylish, energy-saving homes.
                        </Typography>
                    </Button>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Button component="a" href={'/internal-architectural-glazing'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} p={1}>
                            <img src={images.secondary} alt="Logo" style={imgStyle} />
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ textAlign: 'left' }} p={2}>
                            Internal Architectural Glazing
                        </Typography>
                        <Divider variant="middle" />
                        <Typography variant="p" component="div" sx={{ textAlign: 'left' }} p={2}>
                        Enhance your interiors with sleek and contemporary architectural glazing.
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}