import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import ContactForm from './contact_form';
import ReviewsHomepage from '../home/reviews';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export default function ContactUs() {
    // Pull the image query
    const query = useQuery();
    const initialImageUrl = query.get('image');
    const [imageUrl, setImageUrl] = useState(initialImageUrl);

    return (
        <React.Fragment>
            <ContactForm imageUrl={imageUrl} setImageUrl={setImageUrl} />
            <ReviewsHomepage />
        </React.Fragment>
    );
}
