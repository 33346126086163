import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import axios from 'axios';

export default function GenericGallery({ baseUrl }) {
    const [index, setIndex] = useState(-1);
    const [slides, setSlides] = useState([]);

    useEffect(() => {
        if (baseUrl) {
            axios.get(`https://api-dot-wrightglazing.nw.r.appspot.com/${baseUrl}`, { responseType: 'json' })
                .then((response) => {
                    console.log('Raw page data fetched:', response.data);
                    let parsedData;

                    if (typeof response.data === 'string') {
                        try {
                            parsedData = JSON.parse(response.data);
                            console.log('Parsed page data:', parsedData);
                        } catch (error) {
                            console.error('Error parsing JSON string:', error);
                            return;
                        }
                    } else {
                        parsedData = response.data;
                        console.log('Parsed page data:', parsedData);
                    }

                    setSlides(parsedData);
                })
                .catch((error) => {
                    console.error('Error fetching page data:', error);
                });
        }
    }, [baseUrl]);

    const getHref = () => {
        return index >= 0 && slides.length > index ? `/contact-us?image=${encodeURIComponent(slides[index].src)}` : '/contact-us';
    };

    return (
        <React.Fragment>
            <Grid container sx={{ flex: 1, width: '100%', justifyContent: 'center', backgroundColor: '#16a1d8', marginTop: 2, display: 'flex', flexDirection: 'column', p: 3 }}>
                <Typography variant="h3" component="div" sx={{ textAlign: 'center', fontStyle: 'italic', color: '#FFF' }}>
                    Check out some of our work
                </Typography>
                <Typography variant="h5" component="div" sx={{ textAlign: 'center', fontStyle: 'italic', color: '#FFF' }}>
                    Explore our portfolio and discover what we can do for you
                </Typography>
            </Grid>
            <Grid sx={{ paddingTop: 2, paddingBottom: 2 }}>
                <PhotoAlbum
                    layout="rows"
                    photos={slides}
                    targetRowHeight={300}
                    onClick={({ index: current }) => setIndex(current)}
                />
            </Grid>

            <Lightbox
                index={index}
                slides={slides}
                open={index >= 0}
                close={() => setIndex(-1)}
                styles={{ root: { zIndex: 15000 }, container: { backgroundColor: 'rgba(0, 0, 0, 0.9)' } }}
                toolbar={{
                    buttons: [
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100vw', paddingLeft: { xs: 10 }, boxSizing: 'border-box' }}>
                            <Button variant="contained" disableElevation sx={{ marginTop: 1 }} href={getHref()}>Get a price for this product</Button>
                        </Box>,
                        'close',
                    ],
                }}
            />
        </React.Fragment>
    );
}
