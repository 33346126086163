import React from 'react';
import { Grid } from '@mui/material';

export default function DoorDesigner() {
    const iframeHtml = `
        <figure style="margin: 0; width: 100%; height: 100%;">
            <iframe 
                src="https://app.glazingvault.com/system/168"  
                width="100%" 
                height="100%" 
                style="border: 0; width: 100%; height: 100%;">
            </iframe>
        </figure>
    `;

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
                height: 'calc(90vh - 120px)',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',

            }}
            p={8}
        >
            <Grid item xs={11} md={11} sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                display: 'flex',
                padding: 3,
                flexDirection: 'column',
                flex: 1,
                height: '100%',
                marginTop: 3
            }}>
                <div style={{ flex: 1, height: '100%' }} dangerouslySetInnerHTML={{__html: iframeHtml}} />
            </Grid>
        </Grid>
    );
}
