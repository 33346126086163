import React, {useState} from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import axios from 'axios';

export default function ConservatoriesStyles() {
    const [images, setImages] = useState({});

    React.useEffect(() => {
        axios.get('https://api-dot-wrightglazing.nw.r.appspot.com/api/get_conservatories_images', {responseType: 'json'}).then((response) => {
            setImages(response.data)
        })
        
    }, []);

    const imgStyle = {
        maxWidth: '100%',
        height: '100%',
        objectFit: 'cover', // Ensure the image covers the entire container
        borderRadius: 5,
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 2,
        marginRight: 2
    };

    return (
        <Grid container sx={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Grid item xs={12} sx={{backgroundColor: '#16a1d8', textAlign: 'center'}} p={3}>
                <Typography variant="h3" component="div" sx={{ color: '#FFF', fontStyle: 'italic'}}>
                Start exploring
                </Typography>
                <Typography variant="h5" component="div" sx={{textAlign: 'center', fontStyle: 'italic', color: '#FFF'}}>
                Dive into our diverse range of styles
                </Typography>
            </Grid>
            <Grid container xs={11} md={10} sx={{p: {md: 3,  xs: 1}}}>
                <Grid item xs={12} md={4}>
                <Button component="a" href={'/lantern-roofs'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                        <img src={images.lanterns} alt="Logo" style={imgStyle}/>
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Lanterns
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                    Enhance your home with our elegant, custom-built lanterns.
                    </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12}  md={4}>
                <Button component="a" href={'/rooflights'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                        <img src={images.rooflights} alt="Logo" style={imgStyle}/>
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Rooflights
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                    Elevate your living space with our beautifully crafted rooflights.
                    </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12}  md={4}>
                <Button component="a" href={'/conservatories-info'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                        <img src={images.conservatories} alt="Logo" style={imgStyle}/>
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Conservatories
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                    Enhance your home with our elegant, custom-built conservatories.
                    </Typography>
                    </Button>
                </Grid>
                {/* <Grid item xs={4}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                        <img src="/images/home_collage/windows.jpg" alt="Logo" style={imgStyle}/>
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Garden Rooms
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                    Create your perfect retreat with our versatile garden rooms.
                    </Typography>
                </Grid> */}
            </Grid>
        </Grid>
    )
}