import React, { useEffect, useRef, useState } from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import mapboxgl from 'mapbox-gl';
import GoogleMaps from '../home/google_maps_autocomplete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

export default function ContactForm({ imageUrl, setImageUrl }) {
    const [formItems, setFormItems] = useState({
        product: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        email_address: '',
        address: '',
        message: '',
        captcha: ''
    });
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleInputChange = (item) => (event) => {
        setFormItems((prevItems) => ({
            ...prevItems,
            [item]: event.target.value,
        }));
    };

    useEffect(() => {
        if (imageUrl) {
            setFormItems((prevItems) => ({
                ...prevItems,
                product: 'reference-image'
            }));
        }
    }, [imageUrl]);

    const sendGtagEvent = (callback) => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'form_submit_action', {
                event_callback: callback,
                event_timeout: 2000
            });
        } else {
            console.warn('gtag function not available');
            callback(); // Fallback if gtag is not available
        }
    };

    const handleButtonClick = async () => {
        sendGtagEvent(async () => {
            // Set the address
            formItems.address = value.description;
    
            if (formItems.product === 'reference-image') {
                formItems.image = imageUrl;
            }
    
            try {
                const response = await axios.post('https://api-dot-wrightglazing.nw.r.appspot.com/api/contact_form', {
                    data: formItems,
                });
                console.log('Response:', response.data);
                setOpen(true); // Show the success modal
    
                setFormItems({
                    product: '',
                    first_name: '',
                    last_name: '',
                    phone_number: '',
                    email_address: '',
                    address: '',
                    message: '',
                    captcha: ''
                });
    
                setValue('');
                setImageUrl(undefined);
    
            } catch (error) {
                console.error('Error:', error);
            }
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    p: { md: 8, xs: 2 }
                }}
            >
                <Grid item xs={12} md={5} sx={{ alignItems: 'start', paddingRight: { md: 4, xs: 0 }, p: { xs: 1 } }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="div" sx={{ color: '#000' }} >
                                Contact our team
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: 2 }}>
                            <Typography variant="h4" component="div" sx={{ color: '#000' }} >
                                Let's explore what Wrightglazing can do for your home
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item>
                                <Grid item sx={{ display: 'flex', marginTop: 6, marginBottom: 1 }}>
                                    <CheckCircleIcon sx={{ color: '#16a1d8', fontSize: 32, marginRight: 1 }} />
                                    <Typography variant="h6" component="div" sx={{ color: '#000' }} >
                                        Contact us directly by phone or email for assistance
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ display: 'flex', marginTop: 2, marginBottom: 2 }}>
                                    <CheckCircleIcon sx={{ color: '#16a1d8', fontSize: 32, marginRight: 1 }} />
                                    <Typography variant="h6" component="div" sx={{ color: '#000' }} >
                                        Visit our office during business hours for in-person consultations
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ display: 'flex', marginTop: 2, marginBottom: 2 }}>
                                    <CheckCircleIcon sx={{ color: '#16a1d8', fontSize: 32, marginRight: 1 }} />
                                    <Typography variant="h6" component="div" sx={{ color: '#000' }} >
                                        Fill out our online form to request a quote
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ display: 'flex', marginTop: 2, marginBottom: 2 }}>
                                    <CheckCircleIcon sx={{ color: '#16a1d8', fontSize: 32, marginRight: 1 }} />
                                    <Typography variant="h6" component="div" sx={{ color: '#000' }} >
                                        We look forward to helping you with your double glazing needs
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container justifyContent="center" style={{ flex: 1 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box
                                sx={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '20'
                                }}
                            >
                                <Grid container sx={{ diplay: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'row' }} p={3}>
                                    <Grid item >

                                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} p={1}>
                                        <FormControl fullWidth >
                                            <InputLabel id="demo-simple-select-label">Select a product</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formItems.product}
                                                label="Select a product"
                                                onChange={handleInputChange('product')}
                                                sx={{ textAlign: 'left' }}
                                            >
                                                <MenuItem value={'windows'}>Windows</MenuItem>
                                                <MenuItem value={'doors'}>Doors</MenuItem>
                                                <MenuItem value={'conservatories'}>Conservatories</MenuItem>
                                                {imageUrl && (
                                                    <MenuItem value={'reference-image'}>Reference Image</MenuItem>
                                                )}
                                            </Select>

                                        </FormControl>
                                        {imageUrl && (
                                            <Box sx={{ textAlign: 'center', mt: 2 }}>
                                                <img src={imageUrl} alt="Selected Product" style={{ maxWidth: '100%', maxHeight: 300 }} />
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6} p={1}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="outlined-required"
                                            label="First name"
                                            value={formItems.first_name}
                                            onChange={handleInputChange('first_name')}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={6} p={1}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="outlined-required"
                                            label="Last name"
                                            value={formItems.last_name}
                                            onChange={handleInputChange('last_name')}
                                        />

                                    </Grid>

                                    <Grid item xs={12} p={1}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Phone number"
                                            fullWidth={true}
                                            value={formItems.phone_number}
                                            onChange={handleInputChange('phone_number')}
                                        />

                                    </Grid>

                                    <Grid item xs={12} p={1}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Email address"
                                            fullWidth={true}
                                            value={formItems.email_address}
                                            onChange={handleInputChange('email_address')}
                                        />

                                    </Grid>
                                    <Grid item xs={12} p={1}>
                                        <GoogleMaps value={value} setValue={setValue} options={options} setOptions={setOptions} inputValue={inputValue} setInputValue={setInputValue} />
                                    </Grid>
                                    <Grid item xs={12} p={1}>
                                        <TextField

                                            id="outlined-required"
                                            label="Message"
                                            fullWidth={true}
                                            multiline
                                            value={formItems.message}
                                            onChange={handleInputChange('message')}
                                        />

                                    </Grid>
                                    <Grid item xs={12} p={1}>

                                        <GoogleReCaptcha onVerify={(token) => handleInputChange('captcha')({ target: { value: token } })} />
                                    </Grid>
                                    <Grid item xs={12} p={1}>
                                        <Button variant="contained" disableElevation onClick={handleButtonClick}>Request quotation</Button>
                                    </Grid>
                                </Grid>

                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Success</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please allow up to 48 hours for a response.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>

    )
}