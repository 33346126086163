import React, { useEffect } from 'react';
import axios from 'axios';
import { Grid, Typography, Box, Button, Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SeoHeader from './seo_header';
import GenericContent from '../generic/generic_content';
import ReviewsHomepage from '../home/reviews';

export default function Seo({page_name}) {
    const [data, setData] = React.useState({});
    const [headerImage, setHeaderImage] = React.useState('');

    // Get the generic page information
    useEffect(() => {
        if (page_name) {
            const url = `https://api-dot-wrightglazing.nw.r.appspot.com/api/get_page/${page_name}`;

            axios.get(url, { responseType: 'json' })
                .then((response) => {
                    let parsedData;

                    // Check if response.data is a string and parse it if necessary
                    if (typeof response.data === 'string') {
                        try {
                            parsedData = JSON.parse(response.data);
                        } catch (error) {
                            console.error('Error parsing JSON string:', error);
                            return;
                        }
                    } else {
                        parsedData = response.data;
                    }

                    setData(parsedData);
                })
                .catch((error) => {
                    console.error('Error fetching page data:', error);
                });
        }
    }, [page_name]);

    // Get the header image
    useEffect(() => {
        if (data.header_image) {
            const imageUrl = `https://api-dot-wrightglazing.nw.r.appspot.com/api/get_image_as_square?foldername=/${data.header_image}`;

            axios.get(imageUrl, { responseType: 'json' })
                .then((response) => {
                    console.log(response.data);
                    setHeaderImage(response.data.url);
                })
                .catch((error) => {
                    console.error('Error fetching header image:', error);
                });
        }
    }, [data]); // Fetch header image when data.header_image changes

    console.log(data);

    return (
        <React.Fragment>
            <SeoHeader header={data.title} headerImage={headerImage}/>
            <GenericContent content={data.content} header={data.title} />
            <ReviewsHomepage/>
        </React.Fragment>
    )
}