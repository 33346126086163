import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css'

export default function MapPopup({ open, setOpen }) {
    function handleClose() {
        open = setOpen(!open);
    }

    return (
        <React.Fragment>

            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle>Our Location</DialogTitle>
                <DialogContent>
                    <Map
                        mapboxAccessToken="pk.eyJ1IjoibG91aXN3cmlnaHQxMyIsImEiOiJjbHQzOTlhb3MxaDdrMmtvZXhlajUxYm14In0.UzbKfRCmqGWErSkjKuEMmg"
                        initialViewState={{
                            longitude: -0.218000,
                            latitude: 51.367810,
                            zoom: 13
                        }}
                        mapStyle="mapbox://styles/louiswright13/clwm11f0p00o101qx84mn1upj"
                        style={{ height: '40vh', marginBottom: 10}}
                    >
                        <Marker longitude={-0.218000} latitude={51.367810} color="blue"/>
                    </Map>
                    <DialogContentText>
                        94-96 Priory Road <br/>
                        Cheam <br/>
                        Surrey <br/>
                        SM3 8LN

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>

            </Dialog>
        </React.Fragment>


        // </Map>

    )
}