import React from 'react';
import { Grid, Typography, Box, Button, Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function SeoHeader({ headerImage, header }) {

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{
                height: 'calc(100vh - 120px)',
                backgroundImage: `url("${headerImage}")`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundBlendMode: 'multiply',
                backgroundColor: 'rgba(0, 0, 0, 0.3)', // Added background color
                color: '#fff',
            }}
        >
            <Grid item xs={11} md={5} sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                display: 'flex',
                padding: 2,
                flexDirection: 'column'
            }}>
                <Grid item sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', flexDirection: 'column' }}>
                    <Typography variant="h4" component="div" sx={{ color: '#000' }} >
                        {header}
                    </Typography>
                    <Divider variant='fullWidth' sx={{ borderBottomWidth: 3, marginTop: 1, marginBottom: 2, backgroundColor: '#16a1d8' }} />
                    <Grid container sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleIcon sx={{ fontSize: 36, color: '#16a1d8' }} />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="h5" style={{ textAlign: 'left', color: '#000', marginBottom: 3 }}>
                                Outstanding Quality
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: 'left', color: '#000' }}>
                                Our uPVC windows and doors are of exceptional quality, energy-efficient, and environmentally friendly. We provide cutting-edge products that meet your needs for acoustic glass.
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sx={{ marginTop: 3, display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleIcon sx={{ fontSize: 36, color: '#16a1d8' }} />
                        </Grid>
                        <Grid item xs={10} sx={{ marginTop: 2 }}>
                            <Typography variant="h5" style={{ textAlign: 'left', color: '#000', marginBottom: 3 }}>
                                Bespoke Design
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: 'left', color: '#000' }}>
                                Tailor our uPVC windows and doors to suit your tastes. Choose from various colours and glass options to complement your home's style.
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sx={{ marginTop: 3, display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleIcon sx={{ fontSize: 36, color: '#16a1d8' }} />
                        </Grid>
                        <Grid item xs={10} sx={{ marginTop: 2 }}>
                            <Typography variant="h5" style={{ textAlign: 'left', color: '#000', marginBottom: 3 }}>
                                Safe And Secure
                            </Typography>
                            <Typography variant="body2" style={{ textAlign: 'left', color: '#000' }}>
                                Our products feature advanced locking mechanisms and security features. Secured By Design accredited, ensuring your home is safe and secure.
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item>
                    <Grid item sx={{ flex: 1, justifyContent: 'center', display: 'flex' }} xs={12} p={1}>
                        <Button variant="contained" disableElevation href={'/contact-us'}>Request quotation</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}