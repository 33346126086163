import React from 'react';

import DoorsHeader from './doors_header';
import DoorsGallery from './doors_gallery';
import DoorsStyles from './doors_styles';
import ReviewsHomepage from '../home/reviews';

export default function Doors() {

    return (
        <React.Fragment>
            <DoorsHeader />
            <DoorsStyles/>
            <DoorsGallery />
            <ReviewsHomepage/>
        </React.Fragment>
    )
}