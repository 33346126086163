import React from 'react';
import { Grid, Typography, Box, Button, Divider } from '@mui/material';

export default function GenericContent({ content, header }) {

    return (
        <React.Fragment>
            <Grid container sx={{ flex: 1, width: '100%', justifyContent: 'center', backgroundColor: '#16a1d8', marginTop: 2, display: 'flex', flexDirection: 'column' }} p={3}>
                <Typography variant="h3" component="div" sx={{ textAlign: 'center', fontStyle: 'italic', color: '#FFF' }}>
                    {header}
                </Typography>
            </Grid>
            <Grid container sx={{ flex: 1, justifyContent: 'center', marginTop: 2, display: 'flex' }} p={3}>
                <Grid item xs={11} md={11} sx={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Typography variant="h6" component="div" sx={{ textAlign: 'left', color: '#000' }}>
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                <Button variant="contained" disableElevation sx={{marginTop: 2}} href={'/contact-us'}>Get a price now</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}