import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, Grid, Drawer, List, ListItem, Hidden, ListItemText, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Timer10SelectIcon from '@mui/icons-material/Timer10Select';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import MapPopup from './map/map_popup';

export default function Navbar() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setIsScrolled(scrollTop > 40);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsDrawerOpen(open);
    };

    const drawerContent = (
        <Box sx={{ width: 250, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', marginTop: '80px' }}>
                <List sx={{ width: '100%', flexGrow: 1 }}>
                    <ListItem button component={Link} to='/'>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button component={Link} to='/about-us'>
                        <ListItemText primary="About Us" />
                    </ListItem>
                    <ListItem button component={Link} to='/windows'>
                        <ListItemText primary="Windows" />
                    </ListItem>
                    <ListItem button component={Link} to='/doors'>
                        <ListItemText primary="Doors" />
                    </ListItem>
                    <ListItem button component={Link} to='/conservatories'>
                        <ListItemText primary="Lanterns & Rooflights" />
                    </ListItem>
                    <ListItem button component={Link} to='/contact-us'>
                        <ListItemText primary="Contact Us" />
                    </ListItem>
                </List>
            </Box>

            {/* Box at the bottom of the drawer */}
            <Box sx={{ px: 1, py: 1, borderTop: '1px solid #ccc', textAlign: 'center'}}>
                <Button href="tel:+442086444224">
                    <Typography variant="h4" color="#16a1d8" sx={{ fontWeight: 650 }}>
                    0208 644 4224
                    </Typography>
                </Button>

                <Typography variant="body1" color="textSecondary">
                    9am - 5pm Mon - Fri
                </Typography>
                <Box p={1} sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
                    <Button variant="contained" disableElevation component={Link} to='/contact-us'>Request quotation</Button>
                </Box>
            </Box>
        </Box>
    );

    function openLocation() {
        setOpen(true);
    }

    const buttonStyles = {
        fontSize: 16.5,
        fontWeight: 500,
        marginLeft: '0.5em',
        display: { md: 'inherit', xs: 'none' }
    }

    return (
        <Box sx={{ flexGrow: 1, marginBottom: {md: '120px'} }}>
            <Box sx={{ backgroundColor: '#03131A' }}>
                <Grid container sx={{ display: { md: 'flex', xs: 'none' }, alignItems: 'center', justifyContent: 'space-around', color: '#159BD0', fontSize: 14, paddingTop: 1, paddingBottom: 1, height: 40 }}>
                    <Grid item xs={3} sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            10 year guarantee <WorkspacePremiumIcon sx={{ color: 'inherit', fontSize: 'inherit + 1' }} />
                        </Box>
                    </Grid>

                    <Grid item xs={3} sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            Made in the UK <DoneOutlineIcon sx={{ color: 'inherit', fontSize: 'inherit + 1' }} />
                        </Box>
                    </Grid>

                    <Grid item xs={3} sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            10+ years in business <Timer10SelectIcon sx={{ color: 'inherit', fontSize: 'inherit + 1' }} />
                        </Box>
                    </Grid>

                    <Grid item xs={3} sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            Competitive pricing <CurrencyPoundIcon sx={{ color: 'inherit', fontSize: 'inherit + 1' }} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'inherit' }, height: '120px', backgroundColor: '#F8F8F8', position: isScrolled ? 'fixed' : 'absolute', top: isScrolled ? 0 : 'none', width: '100%', zIndex: 10000 }}>
                <Grid container sx={{ 'display': 'flex', px: { xs: 10, sm: 20 } }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
                        <img src="/images/wrightglazing_logo.png" alt="Logo" style={{ maxHeight: '80px', marginLeft: '10', marginRight: '10' }} />
                    </Typography>
                    <Grid item sx={{ flexGrow: 1, display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    <LocationOnIcon sx={{ fontSize: 40, m: 1 }} />
                        <Grid item sx={{}}>
                            <Button component="a" onClick={openLocation} sx={{ textDecoration: 'inherit', textTransform: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                                <Typography color={'#03131A'} sx={{ fontSize: 16 }}>
                                    Our Location
                                </Typography>
                                <Typography color={'#03131A'} sx={{ fontSize: 12 }}>
                                    94-96 Priory Road
                                </Typography>
                            </Button>
                        </Grid>
                        <Divider orientation='vertical' sx={{ marginLeft: 1, marginRight: 1 }} />
                        <DoorFrontIcon sx={{ fontSize: 40, m: 1 }} />
                        <Grid item sx={{}}>
                            <Button component="a" href={'/door-designer'} sx={{ textDecoration: 'inherit', textTransform: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                                <Typography color={'#03131A'} sx={{ fontSize: 16 }}>
                                    Design a Door
                                </Typography>
                                <Typography color={'#03131A'} sx={{ fontSize: 12 }}>
                                    Click Here
                                </Typography>
                            </Button>
                        </Grid>

                        <Divider orientation='vertical' sx={{ marginLeft: 1, marginRight: 1 }} />

                        <LocalPhoneIcon sx={{ fontSize: 40, m: 1 }} />
                        <Grid item sx={{}}>
                            <Button component="a" href={'/contact-us'} sx={{ textDecoration: 'inherit', textTransform: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                                <Typography color={'#03131A'} sx={{ fontSize: 16 }}>
                                    Call Us Now
                                </Typography>
                                <Typography color={'#03131A'} sx={{ fontSize: 12 }}>
                                    0208 644 4224
                                </Typography>
                            </Button>
                        </Grid>

                        <Divider orientation='vertical' sx={{ marginLeft: 1, marginRight: 1 }} />

                        <RequestQuoteIcon sx={{ fontSize: 40, m: 1 }} />
                        <Grid item sx={{}}>
                            <Button component="a" href={'/contact-us'} sx={{ textDecoration: 'inherit', textTransform: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                                <Typography color={'#03131A'} sx={{ fontSize: 16 }}>
                                    Free Quotation
                                </Typography>
                                <Typography color={'#03131A'} sx={{ fontSize: 12 }}>
                                    Click Here
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{
                    flexGrow: 1,
                    position: { md: isScrolled ? 'fixed' : 'absolute' },
                    top: { md: isScrolled ? '80px' : '120px', xs: 0 },
                    transition: 'width 0.3s ease',
                    background: { md: isScrolled ? '#16a1d8' : 'transparent', xs: '#FFF', sm: '#FFF' },
                    height: { md: isScrolled ? '80px' : '120px', xs: '80px' },
                    width: { md: isScrolled ? '100%' : '70%', xs: '100%' },
                    zIndex: 10000
                }}>
                    <AppBar sx={{ position: { md: 'static', xs: 'fixed' }, background: { md: '#16a1d8', xs: '#FFF' }, color: { md: '#FFF', xs: '#000' }, height: 80, justifyContent: { md: 'center', xs: 'left' }, alignItems: 'center', flexDirection: 'row' }}>
                        <Toolbar sx={{ width: '100%', display: 'flex', justifyContent: {xs: 'space-between', md: 'center'}, alignItems: 'center' }}>
                            <IconButton edge="start" color="inherit" aria-label="menu" sx={{ display: { md: 'none' } }} onClick={toggleDrawer(!isDrawerOpen)}>
                                {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
                            </IconButton>

                            {/* Navigation Links */}
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', gap: 2 }}>
                                <Button component={Link} to='/' color="inherit" sx={buttonStyles}>Home</Button>
                                <Button component={Link} to='/about-us' color="inherit" sx={buttonStyles}>About Us</Button>
                                <Button component={Link} to='/windows' color="inherit" sx={buttonStyles}>Windows</Button>
                                <Button component={Link} to='/doors' color="inherit" sx={buttonStyles}>Doors</Button>
                                <Button component={Link} to='/conservatories' color="inherit" sx={buttonStyles}>Lanterns & Rooflights</Button>
                                <Button component={Link} to='/contact-us' color="inherit" sx={buttonStyles}>Contact Us</Button>
                            </Box>



                            {/* Logo Image - Only on small screens */}
                            <Hidden mdUp>
                                <Button to='/' component={Link} >
                                    <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                                        <img src="/images/wrightglazing_logo.png" alt="Logo" style={{ maxHeight: '70px', width: 'auto' }} />
                                    </div>
                                </Button>

                            </Hidden>

                            {/* Phone Call Button - Only for md screens */}
                            <Hidden mdUp>
                                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                                    <IconButton color="inherit" href="tel:+442086444224">
                                        <LocalPhoneIcon />
                                    </IconButton>
                                </Box>
                            </Hidden>


                        </Toolbar>
                    </AppBar>
                </Box>
            </Box>
            <Drawer anchor='left' disableScrollLock={true} open={isDrawerOpen} onClose={toggleDrawer(false)}>
                {drawerContent}
            </Drawer>
            <MapPopup open={open} setOpen={setOpen} />
        </Box>
    );
}
