import React from "react";
import { Grid, Typography, Button } from '@mui/material';
import { ElfsightWidget } from 'react-elfsight-widget'

export default function InstagramHomepage() {

    return(
        <Grid container sx={{flex: 1, width: '100%', justifyContent: 'center', backgroundColor: '#FFFFFF'}} p={3}>
            <Typography variant="h4" component="div" sx={{textAlign: 'center', marginBottom: 3.5, fontStyle: 'italic'}}>
                CHECK OUT OUR INSTAGRAM
            </Typography>
            <Grid item xs={12} sx={{ width: '100%' }}>
            <ElfsightWidget widgetId="ccb8eda6-9390-4115-9270-bb677131f0f6"/>
            </Grid>
            
        </Grid>
    )
}