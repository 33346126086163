import React, { useState } from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import axios from 'axios';

export default function DoorsStyles() {
    const [images, setImages] = useState({});

    React.useEffect(() => {
        axios.get('https://api-dot-wrightglazing.nw.r.appspot.com/api/get_doors_images', {responseType: 'json'}).then((response) => {
            setImages(response.data)
        })
        
    }, []);

    const imgStyle = {
        maxWidth: '100%',
        height: '100%',
        objectFit: 'cover', // Ensure the image covers the entire container
        borderRadius: 5,
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 2,
        marginRight: 2
    };

    return (
        <Grid container sx={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 3}}>
            <Grid item xs={12} sx={{backgroundColor: '#16a1d8', textAlign: 'center'}} p={3}>
                <Typography variant="h3" component="div" sx={{ color: '#FFF', fontStyle: 'italic'}}>
                Start exploring
                </Typography>
                <Typography variant="h5" component="div" sx={{textAlign: 'center', fontStyle: 'italic', color: '#FFF'}}>
                Dive into our diverse range of styles
                </Typography>
            </Grid>
            <Grid container xs={11} md={10} sx={{ p: {md: 3, xs: 1}}}>
                <Grid item xs={12} md={6}>
                <Button component="a" href={'/composite-doors'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                        <img src={images.solidor} alt="Logo" style={imgStyle}/>
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Composite Doors
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                    Elevating home security with style and substance in every door.
                    </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                <Button component="a" href={'/alumimum-bi-folding-doors-patio-doors'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                        <img src={images.bifold} alt="Logo" style={imgStyle}/>
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Aluminium Bi-Folding & Patio Doors
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                    Timeless beauty meets contemporary efficiency in vertical elegance.
                    </Typography>
                    </Button>
                </Grid>
                
                {/* <Grid item xs={12} md={6}>
                <Button component="a" href={'/patio-doors'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                        <img src={images.patio} alt="Logo" style={imgStyle}/>
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Patio Doors
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                    Flexible, innovative design for controlled ventilation and security.
                    </Typography>
                    </Button>
                </Grid> */}

                <Grid item xs={12} md={6}>
                <Button component="a" href={'/upvc-patio-and-french-doors'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                        <img src={images.french} alt="Logo" style={imgStyle}/>
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        UPVC Patio & French Doors
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                    Expansive views, inviting spaces; a luxurious touch.
                    </Typography>
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                <Button component="a" href={'/porches'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                        <img src={images.porches} alt="Logo" style={imgStyle}/>
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Porches
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                    Flexible, innovative design for controlled ventilation and security.
                    </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}