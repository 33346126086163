import React from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';

export default function AboutUsSection() {
    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', p: { md: 8, xs: 2 } }}
        >
            {/* About Us Section */}
            <Grid container direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ backgroundColor: '#16a1d8', p: 1, borderRadius: 1.5, textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ color: '#FFF' }}>About Us</Typography>
                    </Box>
                    <Typography variant="h6" sx={{ mt: 3, color: '#000' }}>
                        The Wrightglazing Company is a family-run business that prides itself on consistently providing a high level of service to customers in the Surrey, London, and Home Counties area.
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 2, color: '#000' }}>
                        Run by the husband-and-wife team Symon and Emma Wright, we have built a solid reputation for honesty, reliability, and no-pressure sales.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            backgroundImage: 'url("/images/about-us/showroom_external.JPG")',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: '100%',
                            height: 400,
                        }}
                    />
                </Grid>
            </Grid>
            
            {/* Our Business Section */}
            <Grid container direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} sx={{ mt: 6 }}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            backgroundImage: 'url("/images/about-us/showroom_internal.JPG")',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: '100%',
                            height: 400,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box sx={{ backgroundColor: '#16a1d8', p: 1, borderRadius: 1.5, textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ color: '#FFF' }}>Our Business</Typography>
                    </Box>
                    <Typography variant="h6" sx={{ mt: 3, color: '#000' }}>
                        We have been at our premises in Cheam for over 15 years. Our newly refurbished showroom allows us to showcase top products in our industry.
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 2, color: '#000' }}>
                        Our loyal and highly skilled installation team is committed to top-quality work with minimal disruption.
                    </Typography>
                </Grid>
            </Grid>
            
            {/* Why Us Section */}
            <Grid container direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ mt: 6, mb: 5 }}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ backgroundColor: '#16a1d8', p: 1, borderRadius: 1.5, textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ color: '#FFF' }}>Why Us?</Typography>
                    </Box>
                    <Typography variant="h6" sx={{ mt: 3, color: '#000' }}>
                        We are members of FENSA, GGF, and Installsure, offering a 10-year insurance-backed guarantee on installations.
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 2, color: '#000' }}>
                        We have been on Checkatrade and Trustatrader since 2010. See what our customers say about us!
                    </Typography>
                    <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
                        <Grid item>
                            <Button variant="contained" href="https://www.checkatrade.com/trades/thewrightglazingco">Checkatrade</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" href="https://www.trustatrader.com/traders/the-wright-glazing-company-ltd-double-glazing-sm1-sm2-sm3">Trustatrader</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            backgroundImage: 'url("/images/about-us/checkatrade_screenshot.png")',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: '100%',
                            height: 400,
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
