import React from 'react';
import { Grid, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"

const images = [,
    { url: "/images/home_slide/2.jpg" },
    { url: "/images/home_slide/3.jpg" },
    { url: "/images/home_slide/4.jpg" },
    { url: "/images/home_slide/5.jpg" }
];

const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

export default function HomeGallery() {

    return (
        <React.Fragment>

<ScrollAnimation animateIn="fadeIn" animateOnce style={{justifyContent: 'center', display: 'flex'}}>
        <Grid container alignItems="center" justifyContent="center" sx={{ flex: 1, backgroundColor: '#FFF', marginTop: 3, marginBottom: 3, flexDirection: {md: 'row', xs: 'row'}}} p={4}>
            <Grid item md={6} xs={12} sx={{ justifyContent: 'center', display: 'flex', marginBottom: 3 }}>
                <Grid item xs={12}>
                    <Grid container sx={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                        <Grid item xs={3}  sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleIcon sx={{ fontSize: 56, color: '#16a1d8'}}/>
                        </Grid>
                        <Grid item xs={9} >
                            <Typography variant="h4" style={{ textAlign: 'left', color: '#000', marginBottom: 5 }}>
                                Outstanding Quality
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'left', color: '#000' }}>
                            Our double glazed uPVC windows and uPVC doors are of exceptional quality. Not only are they energy efficient and environmentally friendly, but they also meet your needs when it comes to acoustic glass. We only give our customers the very best, and most cutting edge, products on the market.
                            </Typography>
                        </Grid>

                        <Grid item xs={3} sx={{marginTop: 3, display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleIcon sx={{ fontSize: 56, color: '#16a1d8'}}/>
                        </Grid>
                        <Grid item xs={9} sx={{marginTop: 3}}>
                            <Typography variant="h4" style={{ textAlign: 'left', color: '#000', marginBottom: 5 }}>
                            Bespoke Design
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'left', color: '#000' }}>
                            All our uPVC windows and uPVC doors can be tailored to suit your tastes and to complement your home. Wrightglazing offer a range of colours to choose from, including woodgrain effects, as well as a selection of glass options.Whether you are looking for contemporary and chic or period charm, we have a range of uPVC windows and uPVC doors to suit your requirements.
                            </Typography>
                        </Grid>


                        <Grid item xs={3} sx={{marginTop: 3, display: 'flex', justifyContent: 'center' }}>
                            <CheckCircleIcon sx={{ fontSize: 56, color: '#16a1d8'}}/>
                        </Grid>
                        <Grid item xs={9} sx={{marginTop: 3}}>
                            <Typography variant="h4" style={{ textAlign: 'left', color: '#000', marginBottom: 5 }}>
                            Safe And Secure
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'left', color: '#000' }}>
                            Security is an important factor when choosing new double glazed uPVC windows and uPVC doors, so all our products come with the most technologically advanced locking mechanisms and security features. We want you to be confident that your home is safe and secure.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={6} xs={12} sx={{ marginBottom: 3, flex: 1, width: '100%'}} p={3}>
            <Slider {...settings}>
                        {images.map((image, index) => (
                            <div key={index} >
                                <img src={image.url} alt={`slide-${index}`} style={{ width: '100%', height: 'auto' }} />
                            </div>
                            
                        ))}
                    </Slider>
            </Grid>
        </Grid>
        </ScrollAnimation>

        </React.Fragment>
    )
}
