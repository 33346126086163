import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"

export default function RockDoorHome() {
    const imgStyle = {
        maxWidth: '100%',
        height: '100%',
        objectFit: 'cover', // Ensure the image covers the entire container
        marginLeft: '10px',
        marginRight: '10px',
        borderRadius: 5
    };

    return (
        <Grid container alignItems="center" justifyContent="center" sx={{backgroundColor: '#F8F8F8'}}>
            <ScrollAnimation duration={1.5} animateIn="fadeIn" animateOnce style={{justifyContent: 'center', display: 'flex'}}>
            <Grid container xs={12} sx={{ alignItems: 'center', justifyContent: 'center', flexDirection: {xs: 'column', md: 'row'}}} p={2}>
                <Grid item xs={12} md={6} sx={{ flex: 1, paddingLeft: {md: 10, xs: 0}}}>
                    <Typography variant="h2" style={{ textAlign: 'left',  color: '#000' }} p={1}>
                        Solidor authorised supplier
                    </Typography>
                    <Typography variant="h4" style={{ textAlign: 'left', color: '#000', fontStyle: 'italic' }} p={1}>
                        Design your dream door today
                    </Typography>
                    <Button variant="contained" disableElevation sx={{marginTop: 2}} href={'/door-designer'}>Door Designer</Button>
                </Grid> 

                <Grid item xs={12} md={6} sx={{ flex: 1 , dislay: 'flex', justifyContent: 'flex-end'}} p={2}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                        <img src="/images/rock_door_home/rockdoor_banner.jpg" alt="Logo" style={imgStyle}/>
                    </Typography>
                </Grid>
            </Grid>
            </ScrollAnimation>
        </Grid> 
    )
}