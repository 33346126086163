import React from 'react';

import ReviewsHomepage from '../home/reviews';
import AbousUsSection from './about-us-section';

export default function AboutUs() {

    return (
        <React.Fragment>
            <AbousUsSection/>
            <ReviewsHomepage/>
        </React.Fragment>
    )
}