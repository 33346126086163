import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"


export default function KeyProducts() {
    const imgStyle = {
        maxWidth: '100%',
        height: '100%',
        objectFit: 'cover', // Ensure the image covers the entire container
        borderRadius: 5,
        marginTop: 2,
        marginBottom: 2,
        marginLeft: 2,
        marginRight: 2
    };

    return (
        <Grid container alignItems="center" justifyContent="center" sx={{backgroundColor: '#F8F8F8', paddingBottom: 3}}>
            <Grid item xs={12} sx={{justifyContent: 'center', display: 'flex', backgroundColor: '#16a1d8', marginBottom: 2, marginTop: 2}}>
                <Grid item xs={9} p={3}>
                    <Typography variant="h3" style={{ textAlign: 'center', color: '#FFF', fontStyle: 'italic' }}>
                        We have been installing windows and doors for over 10 years
                    </Typography>
                    <Typography variant="h5" component="div" sx={{textAlign: 'center', fontStyle: 'italic', color: '#FFF'}}>
                        A decade of reliability, securing homes with our installations
                    </Typography>
                </Grid>
            </Grid>
            <ScrollAnimation animateIn="fadeIn" animateOnce style={{justifyContent: 'center', display: 'inherit', }}>
                <Grid container xs={10} sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: {md: 'row', sm: 'row', xs: 'row'} }}>
                    <Grid item xs={12} sm={6} md={4} sx={{  }}>
                        <Button component="a" href={'/windows'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                                <img src="/images/home_collage/windows.jpg" alt="Logo" style={imgStyle}/>
                            </Typography>
                            <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                                Windows
                            </Typography>
                            <Divider variant="middle"/>
                            <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                                Keep your home warm and secure with windows by Wrightglazing
                            </Typography>
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} sx={{  }}>
                        <Button component="a" href={'/doors'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} p={1}>
                                <img src="/images/home_collage/door.jpg" alt="Logo" style={imgStyle} />
                            </Typography>
                            <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                                Doors
                            </Typography>
                            <Divider variant="middle"/>
                            <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                                Keep your home warm and secure with doors by Wrightglazing
                            </Typography>
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} sx={{  }}>
                        <Button component="a" href={'/conservatories'} sx={{ textDecoration: 'none', color: 'inherit', display: 'block', height: '100%' }}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} p={1}>
                                <img src="/images/home_collage/conservatory.jpg" alt="Logo" style={imgStyle} />
                            </Typography>
                            <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                                Conservatories
                            </Typography>
                            <Divider variant="middle"/>
                            <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                                Keep your home warm and secure with a conservatory by Wrightglazing
                            </Typography>
                        </Button>
                    </Grid>
                
                {/* <Grid item xs={12} sm={6} md={4} sx={{  }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}} p={1}>
                        <img src="/images/home_collage/livin_room.jpg" alt="Logo" style={imgStyle} />
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Livin Rooms
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Keep your home warm and secure with windows by Wrightglazing
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{  }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} p={1}>
                        <img src="/images/home_collage/bi_folding_door.jpg" alt="Logo" style={imgStyle} />
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Bi-Folding Doors
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Keep your home warm and secure with doors by Wrightglazing

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{  }}> 
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} p={1}>
                        <img src="/images/home_collage/orangery.jpg" alt="Logo" style={imgStyle} />
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Orangeries
                    </Typography>
                    <Divider variant="middle"/>
                    <Typography variant="p" component="div" sx={{ textAlign: 'left'}} p={2}>
                        Keep your home warm and secure with a conservatory by Wrightglazing
                    </Typography>
                </Grid> */}
            </Grid>
            </ScrollAnimation>
        </Grid>
    )
}
