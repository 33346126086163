import React from "react";
import { Grid, Typography, Button } from '@mui/material';
import { ElfsightWidget } from 'react-elfsight-widget'

export default function ReviewsHomepage() {

    return(
        <Grid container sx={{flex: 1, width: '100%', justifyContent: 'center', backgroundColor: '#F8F8F8'}} p={3}>
            <Typography variant="h4" component="div" sx={{textAlign: 'center', marginBottom: 3.5, fontStyle: 'italic'}}>
                WHAT OUR CUSTOMERS SAY
            </Typography>
            <Grid item xs={12} sx={{ width: '100%' }}>
            <ElfsightWidget widgetId="7dd68689-f0ee-4d84-abb4-f9407657df73"/>
            </Grid>
        </Grid>
    )
}