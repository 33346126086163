import React, { useState } from 'react';
import { Grid, Typography, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';
import GoogleMaps from './google_maps_autocomplete';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

export default function Hero() {
    const [formItems, setFormItems] = useState({
        product: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        email_address: '',
        address: '',
        captcha: ''
    });
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (item) => (event) => {
        setFormItems((prevItems) => ({
            ...prevItems,
            [item]: event.target.value,
        }));
    };

    const sendGtagEvent = (callback) => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', 'form_submit_action', {
                event_callback: callback,
                event_timeout: 2000
            });
        } else {
            console.warn('gtag function not available');
            callback(); // Fallback if gtag is not available
        }
    };
    

    const handleButtonClick = async () => {
        formItems.address = value?.description || '';

        sendGtagEvent(async () => {
            try {
                const response = await axios.post('https://api-dot-wrightglazing.nw.r.appspot.com/api/contact_form', { data: formItems });
                console.log('Response:', response.data);
                setOpen(true);
                setFormItems({
                    product: '',
                    first_name: '',
                    last_name: '',
                    phone_number: '',
                    email_address: '',
                    address: '',
                    captcha: ''
                });
                setValue('');
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{
                top: { xs: '0px', md: '0px' },
                height: 'calc(100vh - 120px)',
                background: 'url("/images/hero_01.jpg") rgba(0, 0, 0, 0.3)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundBlendMode: 'multiply',
                color: '#fff',
                position: 'relative'
            }}
        >
            <Grid item xs={12}>
                <Grid container justifyContent="center" style={{ height: '100%' }}>
                    <Grid item xs={10} sm={8} md={7} lg={5}>
                        <Box
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 2,
                            }}
                        >
                            <Grid container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }} spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" component="div" sx={{ color: '#000', paddingBottom: {xs: 0, md: 3} }}>
                                        Get a Price
                                    </Typography>
                                    <Typography variant="h6" component="div" sx={{ color: '#000', paddingBottom: {xs: 0, md: 3} }}>
                                        Up to £250 off when you get a free no-obligation quote.
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select a product</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={formItems.product}
                                            label="Select a product"
                                            onChange={handleInputChange('product')}
                                            sx={{ textAlign: 'left' }}
                                        >
                                            <MenuItem value={'windows'}>Windows</MenuItem>
                                            <MenuItem value={'doors'}>Doors</MenuItem>
                                            <MenuItem value={'conservatories'}>Conservatories</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="outlined-required"
                                        label="First name"
                                        value={formItems.first_name}
                                        onChange={handleInputChange('first_name')}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="outlined-required"
                                        label="Last name"
                                        value={formItems.last_name}
                                        onChange={handleInputChange('last_name')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="outlined-required"
                                        label="Phone number"
                                        value={formItems.phone_number}
                                        onChange={handleInputChange('phone_number')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="outlined-required"
                                        label="Email address"
                                        value={formItems.email_address}
                                        onChange={handleInputChange('email_address')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <GoogleMaps value={value} setValue={setValue} options={options} setOptions={setOptions} inputValue={inputValue} setInputValue={setInputValue}/>
                                </Grid>
                                <Grid item xs={12} p={1}>
                                    <GoogleReCaptcha onVerify={(token) => handleInputChange('captcha')({ target: { value: token } })} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" disableElevation onClick={handleButtonClick}>Request quotation</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Success</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please allow up to 48 hours for a response.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
