import React from 'react';
import { Grid, Typography, Box, Button, Divider } from '@mui/material';

export default function GenericHeader({headerImage, subheader, header, description}) {

    return (
        <Grid
                container
                alignItems="center"
                justifyContent="center"
                style={{
                    height: 'calc(100vh - 120px)',
                    backgroundImage: `url("${headerImage}")`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundBlendMode: 'multiply',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Added background color
                    color: '#fff',
                }}
            >
                <Grid item xs={11} md={5} sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    display: 'flex',
                    padding: 3,
                    flexDirection: 'column'
                }}>
                    <Grid item sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', flexDirection: 'column' }}>
                        <Typography variant="h5" component="div" sx={{ color: '#000' }} >
                            {subheader}
                        </Typography>
                        <Divider variant='fullWidth' sx={{ borderBottomWidth: 3, marginTop: 1, marginBottom: 1, backgroundColor: '#16a1d8' }} />
                        <Typography variant="h3" component="div" sx={{ color: '#000' }} >
                            {header}
                        </Typography>
                        <Divider variant='fullWidth' sx={{ borderBottomWidth: 3, marginTop: 1, marginBottom: 2, backgroundColor: '#16a1d8' }} />
                        <Typography variant="h6" component="div" sx={{ color: '#000' }} >
                            {description}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Grid item sx={{ flex: 1, justifyContent: 'center', display: 'flex' }} xs={12} p={1}>
                            <Button variant="contained" disableElevation  href={'/contact-us'}>Request quotation</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
}