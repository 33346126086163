import React, {useEffect} from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Routes, Route, useLocation } from 'react-router-dom';
import {Sugar} from 'react-preloaders';
import {Oval} from 'react-loader-spinner'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ElfsightWidget } from 'react-elfsight-widget'

import AboutUs from './about-us/about-us';
import Home from './home/home';
import Navbar from './navbar';
import Footer from './footer';
import Windows from './windows/windows';
import GenericPage from './generic/generic_page';
import Doors from './doors/doors';
import Conservatories from './conservatories/conservatories';
import ContactUs from './contact_us/contact_us';
import ScrollToTop from './scoll_to_top';
import DoorDesigner from './extras/door_designer';
import Seo from './seo/seo';

let theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            'Barlow Condensed',
            'Playfair',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                h2: {
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 500
                },
                h3: {
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 500
                },
                h4: {
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 500
                },
                h5: {
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 450
                },
                h6: {
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 400
                },
                p: {
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 300
                }
            }
        }
    }
});

theme = responsiveFontSizes(theme);

export default function Main() {

    const location = useLocation();

    useEffect(() => {
        const titles = {
            '/': 'Home',
            '/about-us': 'About Us',
            '/index': 'Home',
            '/windows': 'Windows',
            '/doors': 'Doors',
            '/conservatories': 'Conservatories',
            '/contact-us': 'Contact Us',
            '/door-designer': 'Door Designer',
            '/casement': 'Casement Windows',
            '/upvc-windows': 'uPVC Windows',
            '/flush': 'Flush Windows',
            '/sash': 'Sash Windows',
            '/tilt-and-turn': 'Tilt and Turn Windows',
            '/bay': 'Bay Windows',
            '/triple-glazing': 'Triple Glazing',
            '/secondary-glazing': 'Secondary Glazing',
            '/internal-architectural-glazing': 'Internal Architectural Glazing',
            '/aluminium': 'Aluminium Windows',
            '/solidor': 'Solidor Doors',
            '/composite-doors': 'Composite Doors',
            '/rockdoor': 'Rockdoor',
            '/bi-folding-doors': 'Bi-Folding Doors',
            '/alumimum-bi-folding-doors-patio-doors': 'Aluminium Bi-Folding & Patio Doors',
            '/patio-doors': 'Patio Doors',
            '/french-doors': 'French Doors',
            '/upvc-patio-and-french-doors': 'uPVC Patio & French Doors',
            '/porches': 'Porches',
            '/conservatories-info': 'Conservatories Information',
            '/lantern-roofs': 'Lantern Roofs',
            '/livin-rooms': 'Livin Rooms',
            '/rooflights': 'Rooflights',
            '/double-glazing-sutton': 'Double Glazing Sutton',
            '/double-glazing-cheam': 'Double Glazing Cheam',
            '/double-glazing-carshalton': 'Double Glazing Carshalton',
            '/double-glazing-epsom': 'Double Glazing Epsom',
            '/double-glazing-putney': 'Double Glazing Putney',
            '/double-glazing-fulham': 'Double Glazing Fulham',
            '/windows-sutton': 'Windows Sutton',
            '/windows-cheam': 'Windows Cheam',
            '/windows-carshalton': 'Windows Carshalton',
            '/windows-epsom': 'Windows Epsom',
            '/windows-putney': 'Windows Putney',
            '/windows-fulham': 'Windows Fulham',
            '/aluminium-windows-sutton': 'Aluminium Windows Sutton',
            '/aluminium-windows-cheam': 'Aluminium Windows Cheam',
            '/aluminium-windows-carshalton': 'Aluminium Windows Carshalton',
            '/aluminium-windows-epsom': 'Aluminium Windows Epsom',
            '/aluminium-windows-putney': 'Aluminium Windows Putney',
            '/aluminium-windows-fulham': 'Aluminium Windows Fulham',
            '/upvc-windows-sutton': 'uPVC Windows Sutton',
            '/upvc-windows-cheam': 'uPVC Windows Cheam',
            '/upvc-windows-carshalton': 'uPVC Windows Carshalton',
            '/upvc-windows-epsom': 'uPVC Windows Epsom',
            '/upvc-windows-putney': 'uPVC Windows Putney',
            '/upvc-windows-fulham': 'uPVC Windows Fulham',
        };

        document.title = `The Wrightglazing Company | ${titles[location.pathname] || 'Home'}`;
    }, [location]);

    return (
        <React.Fragment>
            <GoogleReCaptchaProvider reCaptchaKey="6LfG2fMpAAAAAOPCcxeK_TZbluFIGBHXhjoGb73n" styles={{}}>
                <ThemeProvider theme={theme}>
                    <CssBaseline>
                        <Navbar />
                        <Routes>
                            <Route path='/' element={<Home/>}/>
                            <Route path='/about-us' element={<AboutUs/>}/>
                            <Route path='/index' element={<Home/>}/>
                            <Route path='/windows' element={<Windows/>}/>
                            <Route path='/doors' element={<Doors/>}/>
                            <Route path='/conservatories' element={<Conservatories/>}/>
                            <Route path='/contact-us' element={<ContactUs/>}/>

                            {/* Generic pages */}
                            <Route path='/casement' element={<GenericPage page_name='casement' />}/>
                            <Route path='/upvc-windows' element={<GenericPage page_name='upvc-windows' />}/>
                            <Route path='/flush' element={<GenericPage page_name='flush' />}/>
                            <Route path='/sash' element={<GenericPage page_name='sash' />}/>
                            <Route path='/tilt-and-turn' element={<GenericPage page_name='tilt-and-turn' />}/>
                            <Route path='/bay' element={<GenericPage page_name='bay' />}/>
                            <Route path='/triple-glazing' element={<GenericPage page_name='triple-glazing' />}/>
                            <Route path='/secondary-glazing' element={<GenericPage page_name='secondary-glazing' />}/>
                            <Route path='/internal-architectural-glazing' element={<GenericPage page_name='internal-architectural-glazing' />}/>
                            <Route path='/aluminium' element={<GenericPage page_name='aluminium' />}/>
                            <Route path='/solidor' element={<GenericPage page_name='solidor' />}/>
                            <Route path='/composite-doors' element={<GenericPage page_name='composite-doors' />}/>
                            <Route path='/rockdoor' element={<GenericPage page_name='rockdoor' />}/>
                            <Route path='/bi-folding-doors' element={<GenericPage page_name='bi-folding-doors' />}/>
                            <Route path='/alumimum-bi-folding-doors-patio-doors' element={<GenericPage page_name='aluminium-bi-fold-and-patio-doors' />}/>
                            <Route path='/patio-doors' element={<GenericPage page_name='patio-doors' />}/>
                            <Route path='/french-doors' element={<GenericPage page_name='french-doors' />}/>
                            <Route path='/upvc-patio-and-french-doors' element={<GenericPage page_name='upvc-patio-and-french-doors' />}/>
                            <Route path='/porches' element={<GenericPage page_name='porches' />}/>
                            <Route path='/conservatories-info' element={<GenericPage page_name='conservatories' />}/>
                            <Route path='/lantern-roofs' element={<GenericPage page_name='lantern-roofs' />}/>
                            <Route path='/livin-rooms' element={<GenericPage page_name='livin-rooms' />}/>
                            <Route path='/rooflights' element={<GenericPage page_name='rooflights' />}/>
                            
                            {/* Extras */}
                            <Route path='/door-designer' element={<DoorDesigner/>}/>

                            {/* Double glazing seo links */}
                            <Route path='/double-glazing-sutton' element={<Seo page_name='double_glazing_sutton' />}/>
                            <Route path='/double-glazing-cheam' element={<Seo page_name='double_glazing_cheam' />}/>
                            <Route path='/double-glazing-carshalton' element={<Seo page_name='double_glazing_carshalton' />}/>
                            <Route path='/double-glazing-epsom' element={<Seo page_name='double_glazing_epsom' />}/>
                            <Route path='/double-glazing-putney' element={<Seo page_name='double_glazing_putney' />}/>
                            <Route path='/double-glazing-fulham' element={<Seo page_name='double_glazing_fulham' />}/>

                            {/* Windows seo links */}
                            <Route path='/windows-sutton' element={<Seo page_name='windows_sutton' />}/>
                            <Route path='/windows-cheam' element={<Seo page_name='windows_cheam' />}/>
                            <Route path='/windows-carshalton' element={<Seo page_name='windows_carshalton' />}/>
                            <Route path='/windows-epsom' element={<Seo page_name='windows_epsom' />}/>
                            <Route path='/windows-putney' element={<Seo page_name='windows_putney' />}/>
                            <Route path='/windows-fulham' element={<Seo page_name='windows_fulham' />}/>

                            {/* aluminium windows seo links */}
                            <Route path='/aluminium-windows-sutton' element={<Seo page_name='aluminium_windows_sutton' />}/>
                            <Route path='/aluminium-windows-cheam' element={<Seo page_name='aluminium_windows_cheam' />}/>
                            <Route path='/aluminium-windows-carshalton' element={<Seo page_name='aluminium_windows_carshalton' />}/>
                            <Route path='/aluminium-windows-epsom' element={<Seo page_name='aluminium_windows_epsom' />}/>
                            <Route path='/aluminium-windows-putney' element={<Seo page_name='aluminium_windows_putney' />}/>
                            <Route path='/aluminium-windows-fulham' element={<Seo page_name='aluminium_windows_fulham' />}/>

                            {/* upvc window links */}
                            <Route path='/upvc-windows-sutton' element={<Seo page_name='upvc_windows_sutton' />}/>
                            <Route path='/upvc-windows-cheam' element={<Seo page_name='upvc_windows_cheam' />}/>
                            <Route path='/upvc-windows-carshalton' element={<Seo page_name='upvc_windows_carshalton' />}/>
                            <Route path='/upvc-windows-epsom' element={<Seo page_name='upvc_windows_epsom' />}/>
                            <Route path='/upvc-windows-putney' element={<Seo page_name='upvc_windows_putney' />}/>
                            <Route path='/upvc-windows-fulham' element={<Seo page_name='upvc_windows_fulham' />}/>


                        </Routes>
                        <ElfsightWidget widgetId="df8e9a29-df91-40ac-8fe0-ffdfe832acc3"/>
                        <Footer/>
                        
                    </CssBaseline>
                </ThemeProvider>
                <ScrollToTop/>
                
            </GoogleReCaptchaProvider>
            
        </React.Fragment>
    )
}