import React, { useEffect } from 'react';
import axios from 'axios';
import { Grid, Typography, Box, Button, Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import GenericHeader from './generic_header';
import GenericContent from './generic_content';
import GenericGallery from './generic_gallery';
import ReviewsHomepage from '../home/reviews';

const baseUrl = '';

export default function GenericPage({ page_name }) {
    const [data, setData] = React.useState({});
    const [headerImage, setHeaderImage] = React.useState('');

    // Get the generic page information
    useEffect(() => {
        if (page_name) {
            const url = `https://api-dot-wrightglazing.nw.r.appspot.com/api/get_page/${page_name}`;

            axios.get(url, { responseType: 'json' })
                .then((response) => {
                    let parsedData;

                    // Check if response.data is a string and parse it if necessary
                    if (typeof response.data === 'string') {
                        try {
                            parsedData = JSON.parse(response.data);
                        } catch (error) {
                            console.error('Error parsing JSON string:', error);
                            return;
                        }
                    } else {
                        parsedData = response.data;
                    }

                    setData(parsedData);
                })
                .catch((error) => {
                    console.error('Error fetching page data:', error);
                });
        }
    }, [page_name]);

    // Get the header image
    useEffect(() => {
        if (data.header_image) {
            const imageUrl = `https://api-dot-wrightglazing.nw.r.appspot.com/api/get_image_as_square?foldername=/${data.header_image}`;

            axios.get(imageUrl, { responseType: 'json' })
                .then((response) => {
                    console.log(response.data);
                    setHeaderImage(response.data.url);
                })
                .catch((error) => {
                    console.error('Error fetching header image:', error);
                });
        }
    }, [data]); // Fetch header image when data.header_image changes

    return (
        <React.Fragment>
            <GenericHeader header={data.header} subheader={data.subheader} headerImage={headerImage} description={data.description} />
            <GenericContent content={data.content} header={data.subheader} />
            <GenericGallery baseUrl={data.slides} />
            <ReviewsHomepage />
        </React.Fragment>
    );
}
