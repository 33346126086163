import React from 'react';

import ConservatoriesGallery from './conservatories_gallery';
import ConservatoriesHeader from './conservatories_header';
import ConservatoriesStyles from './conservatories_styles';
import ReviewsHomepage from '../home/reviews';

export default function Conservatories() {

    return (
        <React.Fragment>
            <ConservatoriesHeader/>
            
            <ConservatoriesStyles/>
            <ConservatoriesGallery/>
            <ReviewsHomepage/>
        </React.Fragment>
    )
}