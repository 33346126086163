import { Grid, Typography, Box, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import React from "react";
import { Link } from 'react-router-dom';

export default function Footer() {

    return (
        <React.Fragment>
            <Grid container sx={{ flex: 1, backgroundColor: '#03131A', display: 'flex', justifyContent: 'center', flexDirection: { md: 'row', xs: 'column' } }} p={4}>
                <Grid item md={2} sx={{ marginLeft: 2, marginRight: 2, marginBottom: 5 }}>
                    <Typography variant="h6" style={{ textAlign: 'left', color: '#FFF' }}>
                        About Us
                    </Typography>
                    <Divider variant="middle" sx={{ bgcolor: '#FFF', marginTop: 2, marginBottom: 2 }} />
                    <Typography variant="body1" style={{ textAlign: 'left', color: '#FFF' }}>
                        The Wright Glazing Company are double glazing experts in Sutton. We supply and install thousands of homes in Sutton, Croydon, Epsom, Ewell, Chelsea, Kensington, Fulham, Southfields, Surbiton, Putney, Wandsworth, Wimbledon and surrounding West London areas.
                    </Typography>
                </Grid>
                <Grid item md={2} sx={{ marginLeft: 2, marginRight: 2, marginBottom: 5 }}>
                    <Typography variant="h6" style={{ textAlign: 'left', color: '#FFF' }}>
                        Contact Us
                    </Typography>
                    <Divider variant="middle" sx={{ bgcolor: '#FFF', marginTop: 2, marginBottom: 2 }} />
                    <Typography variant="body1" style={{ textAlign: 'left', color: '#FFF' }}>
                        0208 644 4224 <br /> info@wrightglazing.co.uk <br /> 131 Church Hill Road, Cheam, Surrey, SM3 8ED
                    </Typography>
                </Grid>
                <Grid item md={2} sx={{ marginLeft: 2, marginRight: 2, marginBottom: 5 }}>
                    <Typography variant="h6" style={{ textAlign: 'left', color: '#FFF' }}>
                        Quick Links
                    </Typography>
                    <Divider variant="middle" sx={{ bgcolor: '#FFF', marginTop: 2, marginBottom: 2 }} />
                    <Typography variant="body1" style={{ textAlign: 'left', color: '#FFF' }}>
                        <Link to="/windows" style={{ color: '#FFF', textDecoration: 'none' }}>Windows</Link> <br />
                        <Link to="/doors" style={{ color: '#FFF', textDecoration: 'none' }}>Doors</Link> <br />
                        <Link to="/conservatories" style={{ color: '#FFF', textDecoration: 'none' }}>Conservatories</Link>
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{ width: '100%', display: 'flex', flex: 1, backgroundColor: '#000', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="body1" style={{ textAlign: 'center', color: '#FFF' }}>
                    Copyright © 2024 The Wright Glazing Company. All Rights Reserved. Registered in England & Wales, Company Reg. No. 5851933. VAT Registration No. 989 1369 56
                </Typography>
            </Box>
        </React.Fragment>
    )
}
