import React, { useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Grid, Typography } from '@mui/material';

// Define your logos array with image paths
const logos = [
  '/images/accreditations/checkatrade_logo.png',
  '/images/accreditations/fensa_logo.png',
  '/images/accreditations/ggf_logo.jpg',
  '/images/accreditations/TGAS3_logo.png',
  '/images/accreditations/trusted_logo.png',
  '/images/accreditations/checkatrade_logo.png',
  '/images/accreditations/fensa_logo.png',
  '/images/accreditations/ggf_logo.jpg',
  '/images/accreditations/TGAS3_logo.png',
  '/images/accreditations/trusted_logo.png',
  '/images/accreditations/checkatrade_logo.png',
  '/images/accreditations/fensa_logo.png',
  '/images/accreditations/ggf_logo.jpg',
  '/images/accreditations/TGAS3_logo.png',
  '/images/accreditations/trusted_logo.png',
];

// CSS keyframes for continuous scrolling animation
const scrollAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
`;

// Styled-components for the scroll container
const ScrollContainer = styled.div`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
`;

// Applying the animation to a wrapper that will contain all the logos twice (for seamless looping)
const AnimatedWrapper = styled.div`
  display: flex;
  animation: ${scrollAnimation} 40s linear infinite;
`;

const LogoImage = styled.img`
  margin-right: 40px; // Adjust spacing between logos as necessary
  height: 40px; // Adjust logo height as necessary
  width: auto;
  flex: 0 0 auto;
`;

export default function Accreditations() {
  const scrollRef = useRef(null);

  useEffect(() => {
    const container = scrollRef.current;
    const containerWidth = container.offsetWidth;

    // Function to dynamically adjust the width of the AnimatedWrapper based on its content
    const adjustWidth = () => {
      const totalWidth = container.firstChild.offsetWidth;
      if (totalWidth < containerWidth * 4) {
        // Ensuring the animated wrapper is wide enough for a seamless loop
        container.firstChild.style.width = `${totalWidth * 4}px`;
      }
    };

    adjustWidth();
    // Adjusting the width whenever the logos array changes (if it's dynamic) or the component size changes
    window.addEventListener('resize', adjustWidth);
    
    return () => {
      window.removeEventListener('resize', adjustWidth);
    };
  }, [logos.length]);

  return (
    <Grid container sx={{paddingTop: 3.5, paddingBottom: 7, display: 'flex', justifyContent: 'center'}}>
        <Typography variant="h4" component="div" sx={{textAlign: 'center', marginBottom: 3.5, fontStyle: 'italic'}}>
            INDUSTRY LEADING ACCREDITATIONS
        </Typography>
        <ScrollContainer ref={scrollRef}>
        <AnimatedWrapper>
            {logos.concat(logos).map((logo, index) => (
              <LogoImage key={index} src={logo} alt={`Logo ${index}`} />
            ))}
        </AnimatedWrapper>
        </ScrollContainer>
    </Grid>
    
  );
}
