import React from 'react';

import Hero from './hero';
import KeyProducts from './key_products';
import Accreditations from './accrediations';
import RockDoorHome from './rock_door_home';
import ReviewsHomepage from './reviews';
import HomeGallery from './home_gallery';
import Footer from '../footer';
import InstagramHomepage from './instagram_reel';

export default function Home() {

    return (
        <React.Fragment>
            <Hero/>
            <KeyProducts/>
            <Accreditations/>
            <RockDoorHome/>
            <HomeGallery/>
            <ReviewsHomepage/>
            <InstagramHomepage/>
            
        </React.Fragment>
    )
}