import React, { useState} from 'react';
import { Grid, Typography, Box, Button, Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';

export default function WindowsHeader() {
    const [index, setIndex] = useState(-1);
    const [image, setImage] = useState({});

    React.useEffect(() => {
        axios.get('https://api-dot-wrightglazing.nw.r.appspot.com/api/get_image_as_square?foldername=/page_images/windows/hero/window 6.jpg', {responseType: 'json'}).then((response) => {
            setImage(response.data)

            console.log(image);
        })
    }, []);

    return (
        <Grid container sx={{
            flex: 1,
            height: 'calc(100vh - 120px)',
            background: `url("${image.url}") rgba(0, 0, 0, 0.1)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundBlendMode: 'multiply',
            color: '#fff',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Grid item xs={11} md={5} sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                display: 'flex',

                padding: 3,
                flexDirection: 'column'
            }}>
                <Grid item sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', flexDirection: 'column' }}>
                    <Typography variant="h5" component="div" sx={{ color: '#000' }} >
                        Windows
                    </Typography>
                    <Divider variant='fullWidth' sx={{ borderBottomWidth: 3, marginTop: 1, marginBottom: 1, backgroundColor: '#16a1d8' }} />
                    <Typography variant="h3" component="div" sx={{ color: '#000' }} >
                        Windows to Suit Any House
                    </Typography>
                    <Divider variant='fullWidth' sx={{ borderBottomWidth: 3, marginTop: 1, marginBottom: 1, backgroundColor: '#16a1d8' }} />
                </Grid>
                <Grid item>
                    <Grid item sx={{ display: 'flex', marginTop: 2, marginBottom: 1 }}>
                        <CheckCircleIcon sx={{ color: '#16a1d8', fontSize: 32, marginRight: 1 }} />
                        <Typography variant="h6" component="div" sx={{ color: '#000' }} >
                            Designed, manufactured & installed in the UK
                        </Typography>
                    </Grid>
                    <Grid item sx={{ display: 'flex', marginTop: 2, marginBottom: 2 }}>
                        <CheckCircleIcon sx={{ color: '#16a1d8', fontSize: 32, marginRight: 1 }} />
                        <Typography variant="h6" component="div" sx={{ color: '#000' }} >
                            Made-to-measure, with a range of options
                        </Typography>
                    </Grid>
                    <Grid item sx={{ display: 'flex', marginTop: 2, marginBottom: 2 }}>
                        <CheckCircleIcon sx={{ color: '#16a1d8', fontSize: 32, marginRight: 1 }} />
                        <Typography variant="h6" component="div" sx={{ color: '#000' }} >
                            Industry-leading low 0.80 U-value
                        </Typography>
                    </Grid>
                    <Grid item sx={{ display: 'flex', marginTop: 2, marginBottom: 2 }}>
                        <CheckCircleIcon sx={{ color: '#16a1d8', fontSize: 32, marginRight: 1 }} />
                        <Typography variant="h6" component="div" sx={{ color: '#000' }} >
                            Market-leading energy-efficiency & security
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid item sx={{ flex: 1, justifyContent: 'center', display: 'flex' }} xs={12} p={1}>
                    <Button variant="contained" disableElevation  href={'/contact-us'}>Request quotation</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}