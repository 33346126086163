import React from 'react';
import WindowsHeader from './windows_header';
import WindowsStyles from './windows_styles';
import ReviewsHomepage from '../home/reviews';
import WindowsGallery from './windows_gallery';

export default function Windows() {

    return (
        <React.Fragment>
            <WindowsHeader/>
            <WindowsStyles/>
            <WindowsGallery/>
            <ReviewsHomepage/>
        </React.Fragment>
    )
}